.wrapper {
    background-color: var(--primary-color);
    height: 8rem;
    border-radius: 0rem;
    position: absolute;
    width: 100%;
    z-index: 0 ;
}

.cardStyle {
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    box-shadow: 0px 2px 25px -11px rgba(171, 171, 171, 0.75);
    z-index: 100;
    background-color: white;
    overflow: hidden;
}

