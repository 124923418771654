.itemWrapper {
    padding: 0.5rem 1rem 0.5rem 1rem;
    width:100%;
    text-align: center;
    font-weight: 500!important;
    margin-bottom: 1rem;
    cursor: pointer;
}

.shortcutImg {
    max-width: 4rem;
}