.previewImageWrapper {
    background-color: #fafafa;
    width:100%;
    height:10rem;
    text-align: center;
    display:flex;
    justify-content: center;
    align-items: center;
}
.previewImage {
    background-color: #fafafa;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}