.linkButton {
    color:#1f2328 !important;
    font-weight: 100;
    margin-top: 0.5rem !important;
    justify-content: flex-start !important;
}
.activeLinkButton {
    background-color: #f6f7f9 !important;

}


.shortcutModal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 450px;
    box-shadow: 14;
    padding: 2rem;
    border-radius: 1rem;
    background-color: white !important;
}
.shortcutGroup {


}


.shortcutLink {
    padding:1rem;
    margin-bottom:1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
}

.shortcutimage {
    max-height: 3rem;
}

