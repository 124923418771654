body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

:root {
  --primary-color: #1f2328;
  --cta-color: #000000;
  --fc-border-color: transparent;
  --swiper-theme-color: var(--primary-color) !important;
  --swiper-navigation-size: 1.7rem !important;
}

.themeButton {
  color: var(--cta-color) !important;
  border-color: var(--cta-color) !important;
}

.swiper-button-next {
  font-size: small !important;
}

#editorjs {
  font-size: 1.15rem;
}

.ce-block__content,
.ce-toolbar__content {
    max-width: unset;
}
.ce-paragraph[data-placeholder][contenteditable="false"]:empty {
  display: none;
}
.image-tool__caption[contenteditable="false"] {
  font-size: 0.9rem;
  border:none;
  box-shadow: none;
  padding-top:0rem;
}

.codex-editor__redactor {
  width:100%;
}


#mini-cal-wrap .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  min-height: unset !important;
}

.fc-daygrid-day-top{
  justify-content: center;
  margin-bottom: 0;
  padding-bottom: 0;
}
.fc-day-sun {
  color:#f59586 !important;
}

.fc-header-toolbar {
  margin-bottom: 0 !important;
}

.fc-event-main-frame {
  border-radius: 0 !important;
  height:0;
  width:50% !important;
  overflow: hidden !important;
}

.fc-toolbar-title {
  padding: 0rem 0.5rem 0.5rem 0rem !important;
  font-weight: 700 !important;
  font-size: 1.3rem !important;
}

.fc-toolbar-title::first-letter {
  text-transform: uppercase;
}

.fc-daygrid-day-events {
  height: 3px !important;
  padding:0 0.6rem 0 0.6rem !important; 
  margin: 0 !important;
  overflow: hidden !important;
  opacity: 0.7 !important;
}


.fc-daygrid-day-frame {
  padding: 0.5rem 0rem 0.2rem 0.2rem !important;
}

#mini-cal-wrap .fc-toolbar-chunk:first-child,
.full-width {
  margin-top: 15px;
  width: 100%;
}

#mini-cal-wrap .fc-button-group button{
  background-color: var(--primary-color) !important;
  color:white !important;
  border:0 !important;
  padding:0.2rem !important
}


#mini-cal-wrap .fc-toolbar-title {
  font-weight: 100;
  padding-bottom:1rem;
}

#mini-cal-wrap .fc-col-header-cell {
  font-weight: 100 !important;
}

#mini-cal-wrap .fc .fc-daygrid-day.fc-day-today {
  background-color: var(--primary-color) !important;
  color: white !important; 
  border-radius: 50% !important;
  padding-right:0.1rem;
}

#mini-cal-wrap .fc .fc-daygrid-day.fc-day-today a {
  color: var(--white) !important;
 
}


hr {
  height:1px;
  border:0;
  margin: 0.4rem 0 1rem 0;
  background-color: lightgrey;
}

.swiper {
  width: 100%;
  height: 100%;

}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.intranet_tile_header {
  padding: 0.5rem;
  font-weight: 700;
  font-size: 1.3rem;
}