.wrapper {
    background-color: var(--header-backgrond);
    height:50px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1); /* Box shadow for lower part */
    z-index: 1000;
}

.activeMenuItem {
    font-weight: 600;
}

.linkItem {
    text-decoration: none;
    color: inherit; 
}