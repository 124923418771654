.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
    box-shadow: 14;
    padding: 2rem;
    border-radius: 1rem;
    background-color: white !important;
}

.modalTitle {
    font-size: 1.2rem !important;
    font-weight: 500;
    margin-bottom: 1rem;
}

.sidebar {
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.1); /* Box shadow for lower part */
    min-height: 100vh;
}

.actionBar {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 3rem;
    border-top: 1px solid lightgrey;
    background-color: "white";
    z-index: 11000;
}

.actionBarOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
}

.headerWrapper {
    height: 20rem;
    width:100%;
    padding-bottom:1rem;
    background-color: var(--primary-color);
    
}

.postContainer {
    z-index: 1000;
    background-color: white;
}

.postImg {
    border-radius: 1rem;
    overflow: hidden;
    max-width: 100%;
    max-height: 18rem;
    float:right;
}

.tag {
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
    padding: 0.5rem 1rem 0.5rem 1rem;
    float: right;
    margin: 0rem 0.2rem 0.4rem 0;
    font-size: 0.85rem;
}

.previewImageWrapper {
    background-color: #fafafa;
    height:10rem;
    text-align: center;
}

.previewImage {
    background-color: #fafafa;
    max-width: 100%;
    max-height: 10rem;
    border-radius: 1rem;
}

.tagWrapper {
    float:left;
    margin: 0 1rem 1rem 0;
    padding: 0.1rem 0rem 0.2rem 0.3rem;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
}

.stickySwiper {
    height: 15rem;
    overflow:hidden;
}
.stickyImgContainer {
    height: 15rem;
    object-fit: contain;}
.stickyPostImg {
    object-fit: scale-down;
}

.stickyBottom {
    position:absolute;
    bottom:0;
    left:0;
    height:6rem;
    width:100%;
}
.postPreviewCardWrapper {
    display: flex;
    margin: 1rem 0rem 1rem 0rem;
    padding-bottom: 1rem;
    border-radius: 0 !important;
    border-bottom: 1px solid rgb(230, 230, 230);

}
  
.postPreviewImage {
    width: 100%;
    border-radius: 0.5rem;
    height: 10rem;
    max-height: 10rem;
}
.postPreviewCard {
    padding-top: 0;
    position: relative;
    overflow: hidden;
    height:8rem;
}

.postPreviewTransitionLayer {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    height:2rem;
}
.postPreviewTagsContent {
    background-color: white;
    display: flex;
    padding:0rem 0.5rem 0 0.5rem;
}
.postPreviewTags {
    position: absolute;
    bottom: 0;
    left: 0;
    right:0;
    padding-top:0.2rem;
}
