.wrapper {
    width:100%;
}

.chartContainer {
    position: relative;
    display: block;
    height: 14rem;
}



.table {
    width:100%;
    text-Align:left; 
    font-size: 0.9rem;
    margin-top:1rem;
}